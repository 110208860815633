import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DictionaryService } from '../../dictionary/dictionary.service';
import { ViewType } from '../../_enums/view-type';
import { ColumnType, ColumnsDisplayOption } from './column-display-option';
import { TableDisplayParameters } from './table-display-parameters';
import { TaskArrayName, TaskTranslation } from '../../task/enums/task.translation.enum';
import { BuildingAdressPipe } from '../../_pipes/building-adress.pipe';
import { setTranslationFromDictionary } from '../../_helpers/setTranslationFromDictionary';
import { TranslateService } from '@ngx-translate/core';
import { Acceptation, AcceptationStatus } from '../../_models/acceptation.model';
import { UserDetailsPipe } from '../../_pipes/user-details.pipe';
import { DocumentPerm, TaskPerm } from '../../_enums/permission.enum';
import { Board } from '../kanban-board/models/board.model';
import { TaskStatus } from '../../task/enums/task-status.enum';
import { TaskModel } from '../../task/models/task.model';

@Injectable({
  providedIn: 'root'
})
export class ColumnDisplayService {

  setColumnTranslation = setTranslationFromDictionary;

  constructor(
    private datePipe: DatePipe,
    private dictionaryServ: DictionaryService,
    private buildingAdress: BuildingAdressPipe,
    private translate: TranslateService,
    private userDetailsPipe: UserDetailsPipe,
  ) { }

  // TASK COLUMNS
  taskBaseColumns: ColumnsDisplayOption[] = [
    { 
      name: 'number', 
      displayName: 'Numer zadania' 
    },
    { 
      name: 'dateCreated', 
      displayName: 'Data utworzenia',
      displayValueTransform: (row) =>
        this.datePipe.transform(row.dateCreated, "dd-MM-yyyy"),
      valueTransform: (row) => row.dateCreated, 
    },
    { 
      name: 'dateRealization', 
      displayName: 'Data realizacji',
      displayValueTransform: (row) =>
        this.datePipe.transform(row.dateRealization, "dd-MM-yyyy"),
      valueTransform: (row) => row.dateRealization, 
    },
    {
      name: 'userCreated',
      displayName: 'Zlecający',
      displayValueTransform: (row) => this.userDetailsPipe.transform(row?.userCreated),
      valueTransform: (row) => row?.userCreated?.firstName
    },
    { 
      name: 'status', 
      displayName: 'Status',
      displayValueTransform: (row) => this.dictionaryServ.getName(
        this.dictionaryServ[TaskArrayName.Status], row.status
      ),
      columnValue: (row) => row.status,
      dictionaryArrayName: TaskArrayName.Status,
      columnType: ColumnType.Chips
    },
    { 
      name: 'piority', 
      displayName: 'Priorytet',
      displayValueTransform: (row) => this.dictionaryServ.getName(
        this.dictionaryServ[TaskArrayName.Priority], row?.priority
      ),
      columnValue: (row) => row.priority,
      dictionaryArrayName: TaskArrayName.Priority,
      columnType: ColumnType.Chips
    },
    { 
      name: 'topic', 
      displayName: 'Temat',
      displayValueTransform: (row) => { return (
        row?.topic ? (
          row.topic?.translatedName ??
          this.translate?.instant(
            TaskTranslation.Topic + row.topic?.name
          )) : 
        '---'
      )}
    }
  ];

  taskColumns: ColumnsDisplayOption[] = this.taskBaseColumns.concat([
    { 
      name: 'companyTenant', 
      displayName: 'Klient',
      displayValueTransform: (row) => {
        return (row?.companyTenant?.name ? row.companyTenant.name : '- - -') + "\n" +
          (row?.companyTenant ? this.buildingAdress.transform(row.companyTenant) : '')
      },
      valueTransform: (row) => row.companyTenant?.name, 
    },
    { 
      name: 'building', 
      displayName: 'Budynek', 
      displayValueTransform: (row) => {
        return (row.building?.name ? row.building.name : ' ') + "\n" +
          this.buildingAdress.transform(row.building) 
      },
      valueTransform: (row) => row.building.name,
    },
    {
      name: "companyExecutor",
      displayName: "Firma wykonawcy",
      permission: [TaskPerm.READ_ALL],
      displayValueTransform: (row) => {
        return (row.executor?.company) ? 
          (row.executor?.company?.name ?? ' ') + "\n" +
          (row.executor?.company?.city ? row.executor?.company?.city + ", " : ' ') +
          (row.executor?.company?.street ?? ' ') : 
          (row?.companyExecutor ? 
            (row.companyExecutor.name ?? ' ') + "\n" +
            (row.companyExecutor.city ? row.companyExecutor.city + ", " : ' ') +
            (row.companyExecutor.street ?? ' ') :
            '- - -'
          )
      },
      valueTransform: (row) => row.executor?.company ? row.executor?.company?.name : row?.companyExecutor?.name,
    },
    { 
      name: 'executor', 
      displayName: 'Wykonawca',
      permission: [TaskPerm.READ_ALL],
      displayValueTransform: (row) => {
        return row?.executor ? 
          (row?.executor?.firstName + ' ' + row?.executor?.lastName )+ "\n" + row?.executor?.email :
          '- - -'
      },
    },
  ]);

  taskTableParameters: TableDisplayParameters = {
    rowLink: "/task/edit/"
  };

  createTaskBoard(tasks: TaskModel[]): Board {
    const taskKanbanBoard: Board = {
      name: 'tasks',
        columns: [{
          id: TaskStatus.Assigned.toString(),
          name: this.dictionaryServ.getName(this.dictionaryServ.taskStatus,TaskStatus.Assigned),
          value: TaskStatus.Assigned,
          itemProperty: 'status',
          items: tasks.filter(task => task.status === TaskStatus.Assigned),
          backgroundColor: this.dictionaryServ.findColor(this.dictionaryServ[TaskArrayName.Status], TaskStatus.Assigned)
        }, {
          id: TaskStatus.InProgress.toString(),
          name: this.dictionaryServ.getName(this.dictionaryServ.taskStatus,TaskStatus.InProgress),
          value: TaskStatus.InProgress,
          itemProperty: 'status',
          items: tasks.filter(task => task.status === TaskStatus.InProgress),
          backgroundColor: this.dictionaryServ.findColor(this.dictionaryServ[TaskArrayName.Status], TaskStatus.InProgress)
        }, {
          id: TaskStatus.Finished.toString(),
          name: this.dictionaryServ.getName(this.dictionaryServ.taskStatus,TaskStatus.Finished),
          value: TaskStatus.Finished,
          itemProperty: 'status',
          items: tasks.filter(task => task.status === TaskStatus.Finished),
          backgroundColor: this.dictionaryServ.findColor(this.dictionaryServ[TaskArrayName.Status], TaskStatus.Finished)
        }],
        readonly: true,
     }

     return taskKanbanBoard;
  }

  // WAREHOUSE COLUMNS
  warehouseStockColumns: ColumnsDisplayOption[] = [
    { 
      name: 'name', 
      displayName: 'Nazwa' 
    },
    { 
      name: 'internalNumber', 
      displayName: 'Numer wewnętrzny' 
    },
    { 
      name: 'serialNumber', 
      displayName: 'Numer seryjny produktu' 
    },
    { 
      name: 'status', 
      displayName: 'Status produktu',
      displayValueTransform: (row) => this.translate?.instant(
        this.dictionaryServ.getName(
          this.dictionaryServ.articleStockStatus, row.status
        )
      ),
      valueTransform: (row) => row?.status  
    },
    // { 
    //   name: 'quantity',
    //   displayName: 'Ilość',
    //   displayValueTransform: (row) => row?.quantity ? (row?.quantity) : "Brak na magazynie",
    //   valueTransform: (row) => row?.quantity 
    // },
    { 
      name: 'warehouse', 
      displayName: 'Magazyn',
      displayValueTransform: (row) => row?.warehouse?.name,
      valueTransform: (row) => row?.warehouse?.name,
    },
  ];

  warehouseStockTableParameters: TableDisplayParameters = {
    // rowLink: "/warehouse/stock/",
    // rowClick: (row) => { 
    //   this.router.navigate([this.warehouseStockTableParameters.rowLink + row.idArticle]);
    // },
  };

  documentTableParameters: TableDisplayParameters = {
    rowLink: "/document/edit/"
  };

  documentColumns: ColumnsDisplayOption[] = [{
    name: 'name',
    displayName: 'Dokument magazynowy',
    displayValueTransform: (row) => row?.number ?? '---',
    valueTransform: (row) => row?.name
  }, {
    name: 'kind',
    displayName: 'Typ dokumentu',
    displayValueTransform: (row) => row?.kind?.name ?? '---',
    valueTransform: (row) => row?.kind?.type
  },{
    name: 'dateCreated',
    displayName: 'Data utworzenia',
    displayValueTransform: (row) => 
      this.datePipe.transform(row.dateCreated, "dd-MM-yyyy"),
    valueTransform: (row) => row?.dateCreated,
  },{
    name: 'company',
    displayName: 'Firma',
    permission: [DocumentPerm.ALL_COMPANY],
    displayValueTransform: (row) => {
      return (row.company.name ? row.company.name : ' ') + "\n" +
        this.buildingAdress.transform(row.company) 
    },
    valueTransform: (row) => row?.company?.name 
  },{
    name: 'acceptations',
    displayName: 'Akceptacja',
    displayValueTransform: (row) => {
      return this.setAccepatationDetails(row?.acceptations[(row?.acceptations.length - 1)])
    },
    valueTransform: (row) => row?.acceptations, 
    sortable: false
  },{
    name: 'status',
    displayName: 'Status',
    displayValueTransform: (row) => this.translate?.instant(row?.status),
    sortable: false
  }];

  locationDisplay(row, viewType) {
    switch(viewType) {
      case ViewType.TechnicalConditions:
        return (row.building.name ? row.building.name : ' ') + "\n" +
        (row.building.city ? row.building.city + ", " : ' ') +
        (row.building.street ? row.building.street : ' ')
      case ViewType.Order:
        return (row.technicalCondition.building.name ? row.technicalCondition.building.name : ' ') + "\n" +
        (row.technicalCondition.building.city ? row.technicalCondition.building.city + ", " : ' ') +
        (row.technicalCondition.building.street ? row.technicalCondition.building.street : ' ')
      default:
        return ''
    }
  }

  private setAccepatationDetails(acceptation: Acceptation): string {
    let accepted: string = '';
    const userDetails = this.userDetailsPipe.transform(acceptation?.user)

    switch(acceptation?.status) {
      case AcceptationStatus.Accepted: {
        accepted = 'TAK - ' + userDetails;
        break;
      }
      case AcceptationStatus.Rejected: {
        accepted = 'NIE - ' + userDetails;
        break;
      }
      default: {
        accepted = 'Brak akcpetacji'
        break;
      }
    }

    return accepted;
  }

}
