import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { CreateRealizationTime, FTimeControls, RealizationTime, RealizationTimeConfig } from '../../../_models/realization-time.model';
import { AuthenticationService } from '@panel/auth';
import { TimeService } from '../../../_services/time.service';
import { DictionaryValues } from '../../../dictionary/dictionary.model';
import { DictionaryService } from '../../../dictionary/dictionary.service';
import { User } from '../../../user/user.model';
import { UserService } from '../../../user/user.service';
import { HeaderConfig } from '../../dialog-header/dialog-header.model';
import { TranslateService } from '@ngx-translate/core';
import { TimePerm } from '../../../_enums/permission.enum';

@Component({
  selector: 'app-realization-time-add',
  templateUrl: './realization-time-add.component.html',
  styleUrls: ['./realization-time-add.component.scss']
})

export class RealizationTimeAddComponent {

  fControl = FTimeControls;
  realizationTimeForm: FormGroup;

  currentUser: User = null;
  userList: User[] = [];
  defaultDay: Date = new Date();
  errorTime: boolean = false;
  categoryPerm: boolean = false;

  newTimeRealization: CreateRealizationTime = new CreateRealizationTime();

  headerConfig: HeaderConfig = {
    ... new HeaderConfig(),
    headerText: (this.data?.idTime && this.data?.readonly) 
      ? 'Szczegóły czasu realizacji' 
      : 'Zarządzanie czasem realizacji',
    cancelButton: true,
  }

  subscription: Subscription = new Subscription();
 
  constructor(
    public dialogRef: MatDialogRef<RealizationTimeAddComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: RealizationTimeConfig,
    public userServ: UserService,
    public dictionaryServ: DictionaryService,
    private authServ: AuthenticationService,
    private timeServ: TimeService,
    private snackBar: MatSnackBar,
    private translateServ: TranslateService
  ) { }

  async ngOnInit() {
    this.categoryPerm = await this.userServ.hasRolesAsync([TimePerm.CATEGORY_MANAGE]);
    
    if(this.data.currentUser) {
      this.currentUser = this.authServ.currentUserValue;
    };

    if(this.data.idTime) {
      this.loadTimeDetails();
    } else {
      this.newTimeRealization = { ...this.newTimeRealization, ...this.data }
      this.initForm(null);
    }
  }

  compareWithFn(
    users: User, 
    selectedUser: User
  ) {
    return users && selectedUser && users.id === selectedUser.id;
  }

  compareTypesFn(
    types: DictionaryValues, 
    selectedType: DictionaryValues
  ) {
    return (types && selectedType && (types == selectedType)) || (types === selectedType);
  }

  onDismiss() {
    this.dialogRef.close(false);
  }

  getCalculateTime(withSeconds: boolean = false) { 
    const start = this.realizationTimeForm.controls[FTimeControls.TIME_START].value;
    const end = this.realizationTimeForm.controls[FTimeControls.TIME_END].value;

    // Parse the time strings into Date objects
    const startTime = withSeconds ? new Date(`1970-01-01T${start}Z`) : new Date(`1970-01-01T${start}:00Z`);
    const endTime = withSeconds ? new Date(`1970-01-01T${end}Z`) : new Date(`1970-01-01T${end}:00Z`);

    // Calculate the difference in milliseconds
    const diffInMs = endTime.getTime() - startTime.getTime();

    // Convert the difference to hours and minutes
    const diffInHours = Math.floor(diffInMs / 1000 / 60 / 60);
    const diffInMinutes = Math.floor((diffInMs / 1000 / 60) % 60);

    // Format the result as a string
    const diffFormatted = `${diffInHours < 10 ? '0' : ''}${diffInHours}:${diffInMinutes < 10 ? '0' : ''}${diffInMinutes}`;

    // Patch the form control with the formatted difference
    this.realizationTimeForm.controls[FTimeControls.DIFF_TIME].patchValue(diffFormatted);

    return diffFormatted;
  }

  /** Creates the time realization */
  onConfirm() {
    if(!this.setUpTimeRealization()) return;

    this.subscription.add(
      this.timeServ.createTimeRealization(this.newTimeRealization).subscribe(
        (time) => {
          this.openSnack('Dodano czas realizacji', 'custom-snack-bar__success')
         },
        (err) => {
          const msg = err?.error?.code 
            ? ('Nie dodano czasu realizacji - ' + this.translateServ.instant(err.error.code)) 
            : 'Nie dodano czasu realizacji';

          this.openSnack(msg, 'custom-snack-bar__error')
        },
        () => {
          this.dialogRef.close(true);
        },
      )
    );
  }

  /** Updates the time realization */
  onUpdate() {
    if(!this.setUpTimeRealization()) return;

    this.subscription.add(
      this.timeServ.updateTimeRealization(this.data.idTime, this.newTimeRealization).subscribe(
        (time) => {
          this.openSnack('Zapisano czas realizacji', 'custom-snack-bar__success')
         },
        (err) => {
          this.dialogRef.close(false);
          this.openSnack('Nie zaktualizowano czasu realizacji', 'custom-snack-bar__error')
        },
        () => {
          this.dialogRef.close(true);
        },
      )
    );
  }

  /**
   * Validates the form and sets up the newTimeRealization object
   * @returns boolean - if the form is valid or not
   */
  private setUpTimeRealization() {
    if( !this.checkFormValidation() ) {
      return false;
    }

    this.newTimeRealization = {...this.newTimeRealization,...this.realizationTimeForm.value};
    this.newTimeRealization.idUser = this.realizationTimeForm.controls.user.value.id;

    // Set the date to the end of the day to prevent timezone issues
    if(this.data.idTime) this.newTimeRealization.date = new Date(this.newTimeRealization?.date);
    this.newTimeRealization?.date?.setHours(23, 59);

    // If drive is not checked, set timeDrive to null
    if(!(this.realizationTimeForm.controls[FTimeControls.DRIVE].value)) this.newTimeRealization.timeDrive = null;

    return true;
  }

  private initForm(time?: RealizationTime) {
    this.realizationTimeForm = new FormGroup({
      [FTimeControls.USER]: new FormControl({
        value: time?.user ?? this.currentUser, 
        disabled: (time && this.data.readonly) ? true : (!this.data.timeForAllUsers)
      }, [Validators.required]),
      [FTimeControls.DATE]: new FormControl({
        value: time?.date ?? this.defaultDay,
        disabled: (time && this.data.readonly) ? true : false
      }, [Validators.required]),
      [FTimeControls.TIME_START]: new FormControl({
        value: time?.timeStart ? time?.timeStart.substring(0,5) : null,
        disabled: (time && this.data.readonly) ? true : false
      },[Validators.required]),
      [FTimeControls.TIME_END]: new FormControl({
        value: time?.timeEnd ? time?.timeEnd.substring(0,5) : null,
        disabled: (time && this.data.readonly) ? true : false
      },[Validators.required]),
      [FTimeControls.TYPE]: new FormControl({
        value: time ? time?.type : null,
        disabled: (time && this.data.readonly) ? true : false
      },[]),
      [FTimeControls.DESCRIPTION]: new FormControl({
        value: time?.description ?? '',
        disabled: (time && this.data.readonly) ? true : false
      },[Validators.maxLength(2000)]),
      [FTimeControls.DIFF_TIME]: new FormControl({
        value: null, 
        disabled: true
      }, []),
      [FTimeControls.DRIVE]: new FormControl({
        value: time?.drive ?? false,
        disabled: (time && this.data.readonly) ? true : false
      }),
      [FTimeControls.TIME_DRIVE]: new FormControl({
        value: time?.timeDrive ?? '00:00',
        disabled: (time && this.data.readonly) ? true : false
      }),
      [FTimeControls.BLAME_ON]: new FormControl({
        value: time?.blameOn ?? null, 
        disabled: (time && this.data.readonly) ? true : (!this.data?.requireBlameOn)
      }, [Validators.required]),
    });

    if(this.categoryPerm) {
      this.realizationTimeForm.controls[FTimeControls.TYPE].setValidators([Validators.required]);
    }

    this.newTimeRealization = {...this.newTimeRealization,...this.realizationTimeForm.value};
  }

  private checkFormValidation() {
    if(this.realizationTimeForm.invalid){ 
      this.realizationTimeForm.markAllAsTouched();
      return false;
    }

    const splittedStart = this.realizationTimeForm.controls.timeStart.value.split(':');
    const splittedEnd = this.realizationTimeForm.controls.timeEnd.value.split(':');

    if (
      new Date(0, 0, 0, +splittedStart[0], +splittedStart[1], 0, 0) >=
      new Date(0, 0, 0, +splittedEnd[0], +splittedEnd[1], 0, 0)
    ) {
      this.errorTime = true;
      this.realizationTimeForm.controls.timeStart.markAsTouched();
      this.realizationTimeForm.controls.timeEnd.markAsTouched();
      return false;
    }

    this.errorTime = false;

    return true;
  }

  private openSnack(message, className) {
    this.snackBar.open(message, '', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snack-bar', className]
    });
  }

  private loadTimeDetails() {
    this.subscription.add(
      this.timeServ.getTimeRealizationById(this.data.idTime).subscribe({
        next: (time: RealizationTime) => {
          this.initForm(time);
        },
        error: (err) => {
          this.dialogRef.close(false);
          this.openSnack('Nie udało się wczytać szczegółów czasu', 'custom-snack-bar__error')
        }, 
        complete: () => {
          this.realizationTimeForm.controls[FTimeControls.DIFF_TIME].patchValue(
            this.getCalculateTime(true)
          );
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
