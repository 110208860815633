import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {

  script: any;

  private _renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document
  ) { 
    this._renderer = rendererFactory.createRenderer(null, null);
  }

  initChatbot() {
    this.script = this._renderer.createElement('script');
    this.script.type = `module`;
    this.script.text = `
      import Chatbot from "https://cdn.jsdelivr.net/npm/flowise-embed/dist/web.js"
    
      Chatbot.init({
      chatflowid: "d12e02a7-ba31-4562-8ee1-03f0e595ab06",
      apiHost: "https://flowisesti.azurewebsites.net",
      theme: {
        button: {
        right: 20,
        bottom: 20,
        size: 42, // small | medium | large | number
        iconColor: 'white',
        },
        tooltip: {
        showTooltip: false,
        tooltipMessage: 'Dzień dobry, w czym mogę pomóc?👋',
        tooltipBackgroundColor: 'black',
        tooltipTextColor: 'white',
        tooltipFontSize: 16,
        },
        chatWindow: {
        showTitle: true,
        title: 'STI Bot',
        titleAvatarSrc: 'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
        welcomeMessage: 'Cześć! Witaj w MyFacility. \\n Jestem MyFacility Assistant, Twój wirtualny asystent do spraw zarządzania aplikacją. \\n\\n Oto, w czym mogę Cię wesprzeć: \\n - Wyjaśnienia dotyczące funkcji aplikacji \\n - Informacje o modułach i ich zastosowaniach \\n - Instrukcje krok po kroku dotyczące wykonywania konkretnych operacji \\n\\n\\n Jestem tutaj, aby Twoja praca z MyFacility była łatwiejsza i bardziej efektywna! \\n\\n\\n Jak mogę Ci dziś pomóc?',
        errorMessage: 'Przepraszam, nie mogę zrozumieć Twojego pytania. Proszę spróbuj ponownie.',
        backgroundColor: "#ffffff",
        height: 700,
        width: 400,
        fontSize: 16,
        poweredByTextColor: "#303235",
        botMessage: {
          backgroundColor: "#f7f8ff",
          textColor: "#303235",
          showAvatar: true,
          avatarSrc: "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/parroticon.png",
        },
        userMessage: {
          backgroundColor: "#3B81F6",
          textColor: "#ffffff",
          showAvatar: true,
          avatarSrc: "https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png",
        },
        textInput: {
          placeholder: 'Napisz swoje pytanie...',
          backgroundColor: '#ffffff',
          textColor: '#303235',
          sendButtonColor: '#3B81F6',
          maxChars: 50,
          maxCharsWarningMessage: 'Przekroczyłeś limit znaków. Proszę wpisz mniej niż 50 znaków.',
        },
        feedback: {
          color: '#303235',
        },
        footer: {
          textColor: '#303235',
          text: '',
          company: '',
          companyLink: '',
        }
        }
      }
      })
    `;

    this._renderer?.appendChild(this._document.body, this.script);
  }

  destroyChatbot() {     
    if(this.script && this._document.body) {
      this._renderer?.removeChild(this._document.body, this.script);

      const elem = this._document.getElementsByTagName('flowise-chatbot');
      for (let index = (elem.length - 1); index >= 0; index--) {
        elem[index]?.parentNode?.removeChild(elem[index]);
      }
    }
  }

}
