<app-dialog-header 
  [config]="headerConfig"
  (close)="onDismiss()"
></app-dialog-header>

<div *ngIf="realizationTimeForm" class="dialog-content custom-scrollbar">
  <form [formGroup]="realizationTimeForm">
    <!-- user selection -->
    <mat-form-field *ngIf="data.timeForAllUsers">
      <mat-label>Użytkownik</mat-label>
      <mat-select
        [formControlName]="fControl.USER"
        name="user"
        disableOptionCentering
        matInput
        [compareWith]="compareWithFn"
      >
        <mat-option
          *ngFor="let user of userServ.users"
          [value]="user"
        >
          {{ user?.firstName + " " + user?.lastName }}
        </mat-option>
      </mat-select>
      <mat-error>Użytkownik jest wymagany.</mat-error>
    </mat-form-field>

    <!-- day of realization -->
    <mat-form-field>
      <mat-label>Data realizacji</mat-label>
      <input 
        matInput 
        [matDatepicker]="picker"
        [formControlName]="fControl.DATE"
      >
      <mat-datepicker-toggle 
        matIconSuffix 
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker 
        #picker
      ></mat-datepicker>
      <mat-error>Data realizacji jest wymagana.</mat-error>
    </mat-form-field>

    <!-- realization time -->
    <mat-form-field>
      <mat-label>Czas realizacji od</mat-label>
      <input
        matInput
        [formControlName]="fControl.TIME_START"
        type="time"
        name="realizationTimeStart"
        (input)="getCalculateTime()"
      >
      <mat-error>Czas rozpoczęcia realizacji jest wymagany.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Czas realizacji do</mat-label>
      <input
        matInput
        [formControlName]="fControl.TIME_END"
        type="time"
        name="realizationTimeEnd"
        (input)="getCalculateTime()"
      >
      <mat-error>Czas zakończenia realizacji jest wymagany.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Wyliczony czas</mat-label>
      <input
        matInput
        [formControlName]="fControl.DIFF_TIME"
        type="time"
        name="diffTime"
      />
    </mat-form-field>

    <div 
      *ngIf="errorTime" 
      class="error-container error-margin"
    >
      Czas zakończenia musi być większy niż czas rozpoczęcia realizacji.
    </div>

    <!-- kategoria -->
    <mat-form-field *ngIf="categoryPerm">
      <mat-label>Kategoria</mat-label>
      <mat-select
        [formControlName]="fControl.TYPE"
        name="type"
        disableOptionCentering
        matInput
        [compareWith]="compareTypesFn"
      >
        <mat-option
          *ngFor="let type of dictionaryServ.timeType"
          [value]="type.value"
        >
          {{ type.key | translate }}
        </mat-option>
      </mat-select>
      <mat-error>Kategoria czasu jest wymagana.</mat-error>
    </mat-form-field>

    <!-- drive time -->
    <mat-checkbox class="mb-3"
      [formControlName]="fControl.DRIVE"
      name="drive"
      labelPosition="after"
      color="primary"
      (change)="!(realizationTimeForm.controls['drive'].value) 
        ? realizationTimeForm.controls['timeDrive'].setValue('00:00') 
        : null
      "
    >Dojazd</mat-checkbox>

    <mat-form-field *ngIf="realizationTimeForm.controls['drive'].value">
      <mat-label>Czas dojazdu</mat-label>
      <input
        matInput
        [formControlName]="fControl.TIME_DRIVE"
        type="time"
        name="timeDrive"
      />
    </mat-form-field>

    <!-- options of blaming -->
    <div 
      *ngIf="data?.requireBlameOn"
      class="d-flex flex-column realization-time-add__radio--margin"
    >
      <div class="realization-time-add__radio--container">
        <mat-label 
          class="realization-time-add__radio--label"
          [ngClass]="{
            'realization-time-add__radio--label--required': (realizationTimeForm.controls[fControl.BLAME_ON].invalid 
            && realizationTimeForm.controls[fControl.BLAME_ON].touched)
          }"
        >Usterka*</mat-label>
        <mat-radio-group class="d-flex flex-column"
          [formControlName]="fControl.BLAME_ON"
          color="primary"
        >
          <mat-radio-button 
            *ngFor="let blame of dictionaryServ?.realizationTimeBlameOn"
            [value]="blame.value"
          >{{blame.key}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-error *ngIf="
        realizationTimeForm.controls[fControl.BLAME_ON].invalid 
        && realizationTimeForm.controls[fControl.BLAME_ON].touched"
        class="custom-alert"
      >
        Jest wymagane określenie po czyjej stronie jest usterka
      </mat-error>
    </div>

    <!-- description -->
    <mat-form-field>
      <mat-label>Opis</mat-label>
      <textarea
        matInput
        [formControlName]="fControl.DESCRIPTION"
        autocomplete="off"
        rows="2"
        name="description"
      ></textarea>
      <mat-error>Opis może zawierać maksymalnie 2000 znaków.</mat-error>
    </mat-form-field>
  </form>

  <div class="buttons__space-between">
    <button 
      class="btn secondary-button-outline"
      (click)="onDismiss()"
    > 
      {{(data?.idTime && data?.readonly) ? 'Zamknij szczegóły' : 'Anuluj'}}
    </button>

    <button 
      *ngIf="!(data?.idTime)"
      class="btn primary-button"
      (click)="onConfirm()"
    >+ Dodaj czas</button>

    <button 
      *ngIf="(data?.idTime && !data?.readonly)"
      class="btn primary-button"
      (click)="onUpdate()"
    >Zapisz</button>
  </div>
</div>

<app-loader [show]="!realizationTimeForm"></app-loader>
