<div class="div-table">
  <div class="row header">
    <div
      *ngFor="let column of getColumns()"
      [class]="
        'cell bg-secondary ' +
        (column.headerClass ? column.headerClass : '') +
        (column.sortable !== false ? ' cursor-pointer' : '')
      "
      (click)="sortChange(column)"
      [ngClass]="{'cell--border': getColumns().length === 1}"
    >
      <div *appHasRole="column?.permission ?? []" class="col-header-container">
        <div>{{ column.displayName }}</div>
        <div *ngIf="column.sortable !== false" class="sort-header">
          <div>
            <img
              [src]="
                '../../assets/icons/new-sort' +
                (column.name == sortBy && sortDirection == 'ASC'
                  ? '-active'
                  : '') +
                '-up.svg'
              "
            />
          </div>
          <div>
            <img
              [src]="
                '../../assets/icons/new-sort' +
                (column.name == sortBy && sortDirection == 'DESC'
                  ? '-active'
                  : '') +
                '-down.svg'
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!filterServ.filtering">
    <div
      *ngFor="let row of _rows | customFilterPipe: searchText"
      (click)="rowClickHandle(row)"
      [class]="
        'row onhover' +
        (parameters.rowLink || parameters.rowClick ? ' cursor-pointer' : '')
      "
    >
      <div
        *ngFor="let column of row._columns"
        class="cell"
        [ngClass]="[
          parameters.grayRow ? 'bg-gray' : 'bg-white',
          (row._columns.length === 1) ? 'cell--border' : ''
        ]"
        [ngStyle]="calcRowStyle(row)"
      >
        <div *appHasRole="column?.permission ?? []">
          <div *ngIf="column.columnType == columnType.List">
            <div
              mat-list-item
              *ngFor="
                let sb of column.displayValueTransform
                  ? column.displayValueTransform(row)
                  : row[column.name];
                let i = index
              "
            >
              {{ i + 1 }}. {{ sb }}
            </div>
          </div>
          <div *ngIf="column.columnType == columnType.ImageLarge">
            <img
              height="70"
              [src]="
                column.displayValueTransform
                  ? column.displayValueTransform(row)
                  : row[column.name]
              "
            />
          </div>
          <div *ngIf="column.columnType == columnType.Status && row.data">
            <app-status
              [name]="row.data.statusName"
              [color]="row.data.statusColor"
              [title]="row.data.statusTitle"
            ></app-status>
          </div>
          <div *ngIf="column.columnType == columnType.Avatar && row.idUserResponsible">
            <app-avatar 
              [personId]="row.idUserResponsible"
            ></app-avatar>
          </div>
          <div *ngIf="column.columnType == columnType.Avatar && row.user?.id">
            <app-avatar 
              [personId]="row.user.id"
            ></app-avatar>
          </div>
          <div *ngIf="column.columnType == columnType.Tooltip">
            <div *ngIf="column.displayValueTransform" class="tooltip-info">
              <span 
                class="tooltip-info__text">
                {{ column.displayValueTransform ? column.displayValueTransform(row) : row[column.name]}} 
              </span> 
              <mat-icon *ngIf="isTooltipDescription(row)"
                class="pointer" 
                [matTooltip]="getTooltipDescripton(row)"
              >info_outline</mat-icon>
            </div>
          </div>
          <div *ngIf="!column.columnType || column.columnType == columnType.Text">
            <span 
              *ngIf="column.innerText; else normalText"
              [innerHTML]="column.displayValueTransform ? column.displayValueTransform(row) : row[column.name]"
            ></span>
            <ng-template #normalText>
              {{
                column.displayValueTransform
                ? column.displayValueTransform(row)
                : row[column.name]
              }}
            </ng-template>
          </div>
          <div *ngIf="(column.columnType == columnType.Logo) && row.logo?.filePath">
            <img class="logo-column" [src]="row.logo?.filePath">
          </div>
          <div *ngIf="(column.columnType == columnType.Delete)" class="row-delete__container">
            <mat-icon 
              class="row-delete row-delete--icon" 
              (click)="onClickDeleteRow(
                column.displayValueTransform
                ? column.displayValueTransform(row)
                : row[column.name]
              )"
            >delete_outline</mat-icon>
          </div>
          <div *ngIf="(column.columnType == columnType.Chips)">
            <div
              class="chips"
              [ngStyle]="{'background-color': setTextColor(column.columnValue(row), column.dictionaryArrayName)}"
            >
              {{
                column.displayValueTransform
                ? column.displayValueTransform(row)
                : row[column.name]
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>

<div *ngIf="_rows?.length == 0 && !(!rowsReceived || loading || filterServ.filtering)" class="no-data-to-show">
  <mat-icon class="icon-display">search_off</mat-icon>
  <p>Brak wyników do wyświetlenia</p>
</div>

<app-loader 
  [show]="(!rowsReceived || loading || filterServ.filtering)"
></app-loader>
