import { Injectable } from '@angular/core';
import { setTranslationFromDictionary } from '../../_helpers/setTranslationFromDictionary';
import { CardRowType, MobileCardDisplayOption, MobileCardParameters, MobileCardRowDisplay, RowType } from './mobile-card-option';
import { DatePipe } from '@angular/common';
import { DictionaryService } from '../../dictionary/dictionary.service';
import { InitialPipe } from '../../_pipes/initial.pipe';
import { YesNoPipe } from '../../_pipes/yes-no.pipe';
import { BuildingAdressPipe } from '../../_pipes/building-adress.pipe';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDetailsPipe } from '../../_pipes/user-details.pipe';
import { UserService } from '../../user/user.service';
import { TaskArrayName, TaskTranslation } from '../../task/enums/task.translation.enum';
import { DocumentPerm, TaskPerm } from '../../_enums/permission.enum';
import { Acceptation, AcceptationStatus } from '../../_models/acceptation.model';

@Injectable({
  providedIn: 'root'
})
export class RowDisplayService {
  setColumnTranslation = setTranslationFromDictionary;

  constructor(
    private datePipe: DatePipe,
    private dictionaryServ: DictionaryService,
    private initialPipe: InitialPipe,
    private yesNoPipe: YesNoPipe,
    private buildingAdress: BuildingAdressPipe,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private userDetailsPipe: UserDetailsPipe,
    private userServ: UserService,
  ) { }

  // MOBILE TASK ROW
  taskParameters: MobileCardParameters = {
    isInside: false,
    title: (row) => row?.number ? row?.number : 'BRAK NUMERU ZADANIA' ,
    subtitle: (row) => 'Data utworzenia: ' + this.datePipe.transform(row.dateCreated, "dd-MM-yyyy"),
    handleClick: (row: any) => this.router.navigate(['/task/edit/' + row.id], { relativeTo: this.route })
  };

  taskBaseRow: MobileCardRowDisplay[] = [
    { 
      rowType: RowType.DOUBLE,
      data: [{
          name: 'status', 
          displayName: 'Status',
          displayValueTransform: (row) => this.dictionaryServ.getName(
            this.dictionaryServ[TaskArrayName.Status], row.status
          ),
          rowType: CardRowType.DOUBLE_ROW_LEFT,
          iconStyle: (row) => { return this.taskStatusColor(row) }
        },{
          name: 'piority', 
          displayName: 'Priorytet',
          displayValueTransform: (row) => this.dictionaryServ.getName(
            this.dictionaryServ[TaskArrayName.Priority], row?.priority
          ),
          rowType: CardRowType.DOUBLE_ROW_RIGHT,
          iconName: 'chevron_right',
          iconStyle: (row) => { return this.taskPriorityColor(row) }
        }]
    }, { 
      rowType: RowType.SINGLE,
      data: [{
        name: 'topic', 
        displayName: 'Temat',
        displayValueTransform: (row) => { return (
          row?.topic ? (
            row.topic?.translatedName ??
            this.translate?.instant(
              TaskTranslation.Topic + row.topic?.name
            )) : 
          '---'
        )},
        rowType: CardRowType.ICON,
        iconName: 'topic'
      }]
    }, {
      rowType: RowType.SINGLE,
      data:[{
        name: 'dateRealization',
        displayName: 'Data realizacji',
        displayValueTransform: (row) =>
          this.datePipe.transform(row.dateRealization, "dd-MM-yyyy"),
        rowType: CardRowType.ICON,
        iconName: 'schedule'
      }]
    }
  ];

  taskRows: MobileCardRowDisplay[] = this.taskBaseRow.concat([{
      rowType: RowType.SINGLE,
      data: [{
        name: 'companyTenant', 
        displayName: 'Klient',
        displayValueTransform: (row) => {
          return (row?.companyTenant?.name ? row.companyTenant.name : '- - -') + "\n" +
            (row?.companyTenant ? this.buildingAdress.transform(row.companyTenant) : '')
        },
        rowType: CardRowType.ICON,
        iconName: 'business'
      }]
    }, {
      rowType: RowType.SINGLE,
      data: [{
        name: 'building', 
        displayName: 'Budynek', 
        displayValueTransform: (row) => {
          return (row.building?.name ? row.building.name : ' ') + "\n" +
            this.buildingAdress.transform(row.building) 
        },
        rowType: CardRowType.ICON,
        iconName: 'home'
      }]
    }, {
      rowType: RowType.SINGLE,
      data: [{
        name: "companyExecutor",
        displayName: "Firma wykonawcy",
        permission: [TaskPerm.READ_ALL],
        displayValueTransform: (row) => {
          return (row.executor?.company) ? 
            (row.executor?.company?.name ?? ' ') + "\n" +
            (row.executor?.company?.city ? row.executor?.company?.city + ", " : ' ') +
            (row.executor?.company?.street ?? ' ') + "\n" +
            (row?.executor?.firstName + ' ' + row?.executor?.lastName )+ "\n" + row?.executor?.email : 
            (row?.companyExecutor ? 
              (row.companyExecutor.name ?? ' ') + "\n" +
              (row.companyExecutor.city ? row.companyExecutor.city + ", " : ' ') +
              (row.companyExecutor.street ?? ' ') :
              '- - -'
            )
        },
        rowType: CardRowType.ICON,
        iconName: 'engineering'
      }]
    },
  ]);

  // DOCUMENTS ROW
  docParameters: MobileCardParameters = {
    isInside: false,
    title: (row) => row?.number ? row?.number : 'BRAK NUMERU DOKUMENTU' ,
    subtitle: (row) => 'Data utworzenia: ' + this.datePipe.transform(row.dateCreated, "dd-MM-yyyy"),
    handleClick: (row: any) => this.router.navigate(['/document/edit/' + row.id], { relativeTo: this.route })
  }

  docRows: MobileCardRowDisplay[] = [{
    rowType: RowType.SINGLE,
    data: [{
      name: 'kind',
      displayName: 'Typ',
      displayValueTransform: (row) => row?.kind?.name ?? '---',
      rowType: CardRowType.ICON,
      iconName: 'description'
    }]
  },{
    rowType: RowType.SINGLE,
    data: [{
      name: 'company',
      displayName: 'Firma',
      permission: [DocumentPerm.ALL_COMPANY],
      displayValueTransform: (row) => {
        return (row.company.name ? row.company.name : ' ') + "\n" +
          this.buildingAdress.transform(row.company) 
      },
      rowType: CardRowType.ICON,
      iconName: 'business'
    }]
  }, {
    rowType: RowType.SINGLE,
    data: [{
      name: 'acceptations',
      displayName: 'Akceptacja',
      displayValueTransform: (row) => {
        return this.setAccepatationDetails(row?.acceptations[(row?.acceptations.length - 1)])
      },
      rowType: CardRowType.ICON,
      iconNameF: (row) => this.setAcceptationIcon(row?.acceptations[(row?.acceptations.length - 1)])
    }]
  }, {
    rowType: RowType.SINGLE,
    data: [{
      name: 'status',
      displayName: 'Status',
      displayValueTransform: (row) => this.translate?.instant(row?.status),
      rowType: CardRowType.ICON,
      iconName: 'rule_folder'
    }]
  }];

  // MOBILE WAREHOUSE ROW
  warehouseStockParameters: MobileCardParameters = {
    isInside: true,
    title: (row) => row?.name ?? 'BRAK NAZWY PRODUKTU',
    showDeleteBtn: false,
    canDelete: ['stocked_product.delete']
  }

  warehouseStockRows: MobileCardRowDisplay[] = [{
    rowType: RowType.SINGLE,
    data: [{
      name: 'internalNumber', 
      displayName: 'Numer wewnętrzny',
      displayValueTransform: (row) => {{ return row?.internalNumber ?? '---' }},
      rowType: CardRowType.TEXT,
      textDisplay: 'Nr. wew:'
    }]
  }, {
    rowType: RowType.SINGLE,
    data: [{
      name: 'serialNumber', 
      displayName: 'Numer seryjny produktu',
      displayValueTransform: (row) => {{ return row?.serialNumber ?? '---' }},
      rowType: CardRowType.TEXT,
      textDisplay: 'Nr. ser:'
    }]
  }, {
    rowType: RowType.SINGLE,
    data: [{
      name: 'warehouse', 
      displayName: 'Magazyn',
      displayValueTransform: (row) => {{ return row?.warehouse?.name ?? '---' }},
      rowType: CardRowType.TEXT,
      textDisplay: 'Magazyn:'
    }]
  }, {
    rowType: RowType.SINGLE,
    data: [{
      name: 'status', 
      displayName: 'Status produktu',
      rowType: CardRowType.TEXT,
      displayValueTransform: (row) => this.translate?.instant(
        this.dictionaryServ.getName(
          this.dictionaryServ.articleStockStatus, row.status
        )
      ),
      textDisplay: 'Status:'
    }]
  }];

  // MOBILE REALIZATION TIME ROW
  realizationTimeParameters: MobileCardParameters = {
    isInside: true,
    title: (row) => row?.date ? this.datePipe.transform(row.date, "dd.MM.yyyy") : 'BRAK DATY DODANIA',
    showDeleteBtn: true,
    canDelete: ['task.time_can_delete'],
    handleClick: (row: any) => { },
  }

  realizationTimeRows: MobileCardRowDisplay[] = [{
    rowType: RowType.SINGLE,
    data: [{
      name: 'timeType',
      displayName: 'Kategoria',
      rowType: CardRowType.ICON,
      displayValueTransform: (row) => this.translate?.instant(
        this.dictionaryServ.getName(
          this.dictionaryServ.timeType, row.type
        )
      ),
      iconName: 'category' 
    }]
  }, {
    rowType: RowType.SINGLE,
    data: [{
      name: 'time',
      displayName: 'Czas',
      rowType: CardRowType.ICON,
      displayValueTransform: (row) => row?.timeStart + ' - ' + row?.timeEnd,
      iconName: 'schedule' 
    }]
  }, {
    rowType: RowType.SINGLE,
    data: [{
      name: 'description',
      displayName: 'Opis',
      rowType: CardRowType.ICON,
      displayValueTransform: (row) => row?.description?.length > 0 ? row.description : '---',
      iconName: 'description' 
    }]
  }, {
    rowType: RowType.SINGLE,
    data: [{
      name: 'executor',
      displayName: 'Wykonawca',
      rowType: CardRowType.ICON,
      displayValueTransform: (row) => this.userDetailsPipe.transform(row?.user),
      iconName: 'engineering' 
    }]
  }];


  private taskStatusColor(row): string {
    let style: any = {};
    const color = this.setTextColor(row?.status, TaskArrayName.Status);
    if(color) {
      style.backgroundColor = color
    }

    return style;
  }

  private taskPriorityColor(row): string {
    let style: any = {};

    const rotate = this.setPriorityIconRotate(row?.priority);
    const color = this.setTextColor(row?.priority, TaskArrayName.Priority);

    if(rotate) { style.rotate = rotate }
    if(color) { style.color = color }

    return style;
  }

  private setTextColor(key: number, arrayName: string): string {
    return this.dictionaryServ.findColor(this.dictionaryServ[arrayName], key)
  }

  private setPriorityIconRotate(priority: number) {
    switch (priority) {
      case 1: {
        return "90deg";
      }
      case 2: {
        return "0deg";
      }
      case 3: {
        return "-90deg";
      }
      default: {
        return null;
      }
    }
  }

  private setAccepatationDetails(acceptation: Acceptation): string {
    let accepted: string = '';
    const userDetails = this.userDetailsPipe.transform(acceptation?.user)

    switch(acceptation?.status) {
      case AcceptationStatus.Accepted: {
        accepted = 'TAK - ' + userDetails;
        break;
      }
      case AcceptationStatus.Rejected: {
        accepted = 'NIE - ' + userDetails;
        break;
      }
      default: {
        accepted = 'Brak akcpetacji';
        break;
      }
    }

    return accepted;
  }

  private setAcceptationIcon(acceptation: Acceptation): string {
    switch(acceptation?.status) {
      case AcceptationStatus.Accepted: {
        return 'thumb_up_alt'
      }
      case AcceptationStatus.Rejected: {
        return 'thumb_down_alt'
      }
      default: {
        return 'thumb_down_alt'
      }
    }
  }

}

