import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Board, BoardAction } from './models/board.model';
import { BehaviorSubject, of } from 'rxjs';
import { MobileCardParameters, MobileCardRowDisplay } from '../mobile-card-view/mobile-card-option';
import { BoardColumn } from './models/column.model';
import { FilterService } from '../filter/filter.service';

@Component({
  selector: 'app-kanban-board',
  templateUrl: './kanban-board.component.html',
  styleUrls: ['./kanban-board.component.scss']
})
export class KanbanBoardComponent implements OnInit {
  of = of;
  
  @Input()
  board: BehaviorSubject<Board> = new BehaviorSubject(null);

  @Input()
  rowsDisplay: MobileCardRowDisplay[] = [];

  @Input()
  parameters: MobileCardParameters;

  @Output()
  action: EventEmitter<BoardAction> = new EventEmitter();
  
  constructor(
    public filterServ: FilterService,
  ) { }

  ngOnInit() { }

  drop(event: CdkDragDrop<string[]>, col: BoardColumn) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    const action: BoardAction = {
      item: event.item.data,
      itemProperty: col.itemProperty,
      value: col.value,
      action: 'update'
    }

    this.action.emit(action);
  }

  ngOnDestroy() {

  }
}
