<ng-container *ngIf="!(filterServ.filtering)">
  <ng-container *ngFor="let row of (rows | async)">
    <mat-card
      matRipple
      [matRippleDisabled]="parameters?.handleClick ? false : true"
      class=" margin__bottom--8 prevent-select"
      [ngClass]="[
        (parameters?.handleClick) ? 'pointer-hover': '',
        (customCss?.length > 0) ? customCss : ''
      ]"
      (click)="parameters?.handleClick ? parameters?.handleClick(row) : null"
    >
      <mat-card-header [ngClass]="{'card-view__no-padding': parameters.isInside}">
        <div *ngIf="parameters.showDeleteBtn" class="row width__100">
          <div class="col-9 card-view__col">
            <mat-card-title *ngIf="parameters?.title">{{ parameters?.title(row) ?? '' }}</mat-card-title>
          </div>
          <div 
            *appHasRole="parameters.canDelete ?? null"
            class="col-3 card-view__col justify-content--end"
          >
            <mat-icon 
              class="row-delete row-delete--icon" 
              (click)="onDeleteTime(row.id, $event)"
            >delete_outline</mat-icon>
          </div>
        </div>
        <mat-card-title *ngIf="!parameters?.showDeleteBtn">{{ parameters?.title(row) ?? '' }}</mat-card-title>
        <mat-card-subtitle *ngIf="parameters?.subtitle">{{ parameters?.subtitle(row) }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content [ngClass]="{'card-view__no-padding': parameters.isInside}">
        <div class="card-view__container">
          <ng-container *ngFor="let display of rowsDisplay">
  
            <ng-container *ngIf="display.rowType === RowType.SINGLE">
              <div 
                *appHasRole="display?.data[0]?.permission ?? []"
                class="card-view__row card-view__multi-row margin__bottom--8"
              >
                <div class="row">
                  <ng-container *ngIf="display?.data[0]?.rowType == CardRowType.ICON">
                    <div class="col-2 card-view__col">
                      <mat-icon *ngIf="display?.data[0]?.iconName">{{ display?.data[0]?.iconName ?? '' }}</mat-icon>
                      <mat-icon *ngIf="display?.data[0]?.iconNameF">{{ display?.data[0]?.iconNameF(row) ?? '' }}</mat-icon>
                    </div>
                    <div class="col-10 card-view__col">
                      {{ display?.data[0]?.displayValueTransform ? (display?.data[0]?.displayValueTransform(row)) : (display?.data[0][row.name]) }}
                    </div>
                  </ng-container>
    
                  <ng-container  *ngIf="display?.data[0]?.rowType == CardRowType.TEXT">
                    <div class="col-4 card-view__col pe-0">
                      {{ display?.data[0]?.textDisplay ?? '' }}
                    </div>
                    <div class="col-8 card-view__col ps-0">
                      {{ display?.data[0]?.displayValueTransform ? (display?.data[0].displayValueTransform(row)) : (display?.data[0][row.name]) }}
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
  
            <ng-container *ngIf="display.rowType === RowType.DOUBLE">
                <div class="row">
                  <div *ngIf="display?.data[0]?.rowType === CardRowType.DOUBLE_ROW_LEFT" class="col card-view__col">
                    <div 
                      *appHasRole="display?.data[0]?.permission ?? []"
                      class="card-view__row card-view__indicator--row margin__bottom--8"
                    >
                      <span
                        class="card-view__indicator--dot"
                        [ngStyle]="display?.data[0]?.iconStyle(row)"
                      ></span>
                      <span>
                        {{ display?.data[0]?.displayValueTransform ? (display?.data[0]?.displayValueTransform(row) | translate) : (display?.data[1][row.name] | translate) }}
                      </span>  
                    </div>
                  </div>
                  <div *ngIf="display?.data[1]?.rowType === CardRowType.DOUBLE_ROW_RIGHT" class="col card-view__col">
                    <div 
                      *appHasRole="display?.data[1]?.permission ?? []" 
                      class="card-view__row card-view__indicator--row margin__bottom--8"
                    >
                      <mat-icon 
                        [ngStyle]="display?.data[1]?.iconStyle(row)"
                      >{{ display?.data[1]?.iconName ?? '' }}</mat-icon>
                      {{ display?.data[1]?.displayValueTransform ? (display?.data[1]?.displayValueTransform(row) | translate) : (display?.data[1][row.name] | translate) }}
                    </div>
                  </div>
                </div>
            </ng-container>
  
          </ng-container>
        </div>
      </mat-card-content>
      <!-- Footer need for correct ripple effect -->
      <mat-card-footer [ngClass]="{'card-view__no-padding': parameters.isInside}"></mat-card-footer>
    </mat-card>
  </ng-container>
</ng-container>

<div *ngIf="(rows | async)?.length === 0" class="no-data-to-show">
  <mat-icon class="icon-display">search_off</mat-icon>
  <p>Brak danych do wyświetlenia</p>
</div>

<app-loader 
  [show]="!(rows | async) || filterServ.filtering"
></app-loader>
