export enum TaskMenuAction {
  CHANGE_STATUS = 'changeStatus',
  CHANGE_EXECUTOR = 'changeExecutor',
  SIGN_PROTOCOL = 'signProtocol',
  ADD_NEW_TASK = 'addNewTask',
  ADD_REALZATION_TIME = 'addRealizationTime',
  ASSIGNE_PRODUCT = 'assigneProduct',
  SEND_NOTIFICATION = 'sendNotification',
  SEND_EQUIPMENT_TO_SERVICE = 'sendEquipmentToService',
  CALL_CREATOR = 'callCreator',
}