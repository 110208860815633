import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subscription } from 'rxjs';
import { RealizationTime, RealizationTimeConfig, TimeDisplayConfiguration, TimeQueryParameters } from '../../../_models/realization-time.model';
import { TimeService } from '../../../_services/time.service';
import { DictionaryService } from '../../../dictionary/dictionary.service';
import { ComponentsService } from '../../components.service';
import { ConfirmDialogType } from '../../confirm-dialog/confirm-dialog-parameters';
import { Utils } from '../../../_helpers/utils';
import { UserService } from '../../../user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { RealizationTimeAddComponent } from '../realization-time-add/realization-time-add.component';
import { ThisPlatformService } from '../../../_services/this-platform.service';
import { TaskMenuAction } from '../../../task/enums/task-action-menu.enum';
import { RowDisplayService } from '../../mobile-card-view/row-display.service';
import { of } from 'rxjs';
import { DeleteEvent } from '../../mobile-card-view/mobile-card-option';
import { TimePerm } from '../../../_enums/permission.enum';

@Component({
  selector: 'app-realization-time-display',
  templateUrl: './realization-time-display.component.html',
  styleUrls: ['./realization-time-display.component.scss']
})
export class RealizationTimeDisplayComponent {

  of = of;

  @Input()
  config: TimeDisplayConfiguration = new TimeDisplayConfiguration();

  @Input()
  query: TimeQueryParameters = new TimeQueryParameters();

  @Input()
  onClickAction: BehaviorSubject<string> = new BehaviorSubject(null);

  @Output()
  deletedTime: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  menuOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  array: EventEmitter<RealizationTime[]> = new EventEmitter<RealizationTime[]>();

  timeRealization: RealizationTime[] = [];
  subscription: Subscription = new Subscription();

  refresh: boolean = false;

  // booleans
  categoryPerm: boolean = false;

  constructor(
    public timeServ: TimeService,
    public dictionaryServ: DictionaryService,
    public p: ThisPlatformService,
    public rowServ: RowDisplayService,
    private snackBar: MatSnackBar,
    private componentsServ: ComponentsService,
    private dialog: MatDialog,
    private userServ: UserService
  ) { }

  ngOnInit() {
    this.checkPermissions();
    this.getTime();
    this.eventsAction();
    this.rowServ.realizationTimeParameters.showDeleteBtn = !this.config.readonly;
    this.rowServ.realizationTimeParameters.handleClick = (row: RealizationTime) => {
      this.menuOpen.emit(true);
      this.onClickAddRealizationTime(row.id)
    };
  }

  onDeleteTime(click?: DeleteEvent) {
    click?.event?.stopPropagation();

    this.componentsServ.ShowDialog(
      "Usuwanie czasu realizacji", 
      "Czy na pewno usunąć czas realizacji?", 
      () => {
        this.subscription.add(
          this.timeServ.deleteTimeRealization(click?.idObject).subscribe(
            (res) => { 
              this.refresh = true;
              this.getTime(); 
            },
            (err) => {
              this.refresh = false;
              Utils.openSnack(this.snackBar,'Błąd podczas usuwania czasu realizacji','custom-snack-bar__error');
            },
            () => {
              Utils.openSnack(this.snackBar,"Czas realizacji został usunięty", 'custom-snack-bar__success');
              this.deletedTime.emit(true);
              this.menuOpen.emit(false);
            }
          )
        );
      }, ConfirmDialogType.Danger,
    );
  }

  onClickAddRealizationTime(idTime?: number, event?: Event) {
    if(event) { event.stopPropagation(); }

    const addRealizationData: RealizationTimeConfig = {
      currentUser: true,
      idTableObject: Number(this.config.idObject),
      tableName: this.config.tableName,
      timeForAllUsers: this.config.addTimeAllPermission,
      requireBlameOn: this.config.requireBlameOn,
      idTime: idTime,
      readonly: this.config.readonly,
    }

    const dialogRef = this.dialog.open(RealizationTimeAddComponent, {
      maxWidth: '600px',
      disableClose: true,
      data: addRealizationData,
    });

    dialogRef.beforeClosed().subscribe(
      (dialogResult) => {
        if (dialogResult) {
          this.refresh = true;
          this.getTime();
        }
      }
    );

    dialogRef.afterClosed().subscribe(() => {
      this.menuOpen.emit(false);
      this.onClickAction.next(null);
    });
  }

  private async getTime() {
    if(this.config.getAll) {
      this.subscription.add(
        this.timeServ.getAllTimeRealization(this.query).subscribe({
          next: (time) => {
            this.timeRealization = time;
            this.array.emit(time)
          },
          error: () => { this.refresh = false; },
          complete: () => { this.refresh = false;}
        })
      );
    } else {
      this.subscription.add(
        this.timeServ.getTimeRealizationById(Number(this.query.idTableObject)).subscribe({
          next: (time) => {
            this.timeRealization.push(time);
          },
          error: () => { this.refresh = false; },
          complete: () => { this.refresh = false;}
        })
      );
    }
  }

  private eventsAction() {
    this.subscription.add(
      this.onClickAction?.subscribe((click) => {
        switch(click) {
          case TaskMenuAction.ADD_REALZATION_TIME: {
            this.onClickAddRealizationTime();            
            break;
          }
          default: {
            break;
          }
        }
      })
    );
  }

  private async checkPermissions() {
    this.categoryPerm = await this.userServ.hasRolesAsync([TimePerm.CATEGORY_MANAGE]);
  }

  ngOnDestroy() { 
    this.subscription?.unsubscribe();
  }

}
