import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UserService } from '../../user/user.service';
import { UserChangeConfig } from './user-change-config';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from '../../user/user.model';

@Component({
  selector: 'app-user-change',
  templateUrl: './user-change.component.html',
  styleUrls: ['./user-change.component.scss']
})
export class UserChangeComponent {

  changeUser: FormControl = new FormControl({
    value: null,
    disabled: false
  }, [Validators.required]);
  
  subscription: Subscription = new Subscription();

  usersList: User[] = [];

  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: UserChangeConfig,
    public dialogRef: MatDialogRef<UserChangeComponent>,
    public userServ: UserService,
  ) { }

  async ngOnInit() {
    this.loading = true;
    await this.loadUsers();
  }

  save() {
    if(
      this.changeUser?.errors || 
      this.changeUser.invalid || 
      this.changeUser.disabled
    ) {
      return;
    }
  
    this.dialogRef.close(this.changeUser?.value?.id);
  }

  cancel() {
    this.dialogRef.close();
  }

  private async loadUsers() {
    this.subscription.add(
      this.userServ.getAll({
        idCompany: this.data.onlyOwnCompany ? this.data?.currentCompany?.id : null,
        getCompanyUsers: this.data.onlyOwnCompany,
        assignedCompanies: true
      }).subscribe({
        next: (users) => {
          this.usersList = users?.filter(user => (user.id != this.data?.currentUser?.id));
          (this.usersList.length == 0) ? this.changeUser.disable() : this.changeUser.enable();
        },
        error: (err) => {
          console.error(err);
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        }
      }   
    ));
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

}
